<template>
  <v-container>
    <v-card>
      <v-card-text>
        <video class="mx-auto col" muted autoplay loop playinline>
          <source :src="require('@/assets/video/introscan.mp4')" type="video/mp4">
          <source :src="require('@/assets/video/introscan.webm')" type="video/webm">
        </video>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// import links from '../data/links';
import logoIntroscan from '@/assets/introscan-logo.svg';
import logoENTE from '@/assets/ente-logo.svg';
import logoAKS from '@/assets/aks-logo.svg';

export default {
  data() {
    return {
      links: [
        {
          title: 'Роботизированные',
          subtitle: 'АО «ИнтроСкан Технолоджи», год основания 2013',
          description: 'Системный Интегратор, Держатель технологий Центр обработки данных внутритрубной диагностики.',
          logo: logoIntroscan,
          link: '#',
        },
        {
          title: 'Диагностические',
          subtitle: 'ООО «ЭНТЭ», год основания 2000',
          description: 'Эксперты, операторы РДК, специалисты НК. Исполнитель работ по диагностике объектов.',
          logo: logoENTE,
          link: 'https://www.ente-ltd.ru/',
        },
        {
          title: 'Системы',
          subtitle: 'ООО «АКС», год основания 1991',
          description: 'Разработчики, изготовители радиоэлектронной аппаратуры. Производство сканеров-дефектоскопов.',
          logo: logoAKS,
          link: 'https://www.acsys.ru/',
        },
      ],
    };
  },
};
</script>
